import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import mask from "@alpinejs/mask";
import continisTheme from "@continis/theme";
import Sortable from "sortablejs";

Alpine.plugin(collapse);
Alpine.plugin(mask);
Alpine.plugin(continisTheme);

window.htmx = require('htmx.org');
window.Sortable = Sortable;

Alpine.start();
